import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { paths } from "../../lib";
import _ from "lodash";

import api from '../../lib/api';
import appState from '../../state/App';
import userState from '../../state/User';

import { LoginForm } from "@dataplan/react-components/dist/components/ui/login_form";

import { defaultAccentColour } from '../../Colours';

import styles from './Login.module.scss';
import titles from '@dataplan/react-components/src/components/ui/login_form/LoginHeader.module.scss';

class Login extends React.Component {

    static propTypes = {
        userState: PropTypes.shape(userState.getPropTypes()).isRequired,
        appState: PropTypes.shape(appState.getPropTypes()).isRequired,
        history: PropTypes.object.isRequired,
    };

    /**
     * Creates an instance of the login page
     *
     * @param {object} props Input props
     */
    constructor (props) {
        super(props);

        this.state = {
            loginStatus: null,
            loginMessage: "",
            validationMessage: "",
            loadingCompleted: false,
        };
    }

    /**
     * Called just after the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        const { history } = this.props;

        // Redirect to the dashboard if the user is already logged in
        if (this.props.userState.loggedIn) {
            history.push(paths.dashboard);
        }
    }

    /**
     * Makes initial loading API requests once container animations are done
     *
     * @return {void}
     */
    loadInitialData () {
        api.get("/companies")
            .then(async ({ data: companies }) => {
                const payrolls = await Promise.all(companies.map((company) => {
                    return api.get(`/company/${company.id}/payrolls`);
                }));

                return {
                    companies,
                    payrolls: payrolls.reduce((acc, { data }) => {
                        acc.push(...data);

                        return acc;
                    }, []),
                };
            }).then(async ({ companies, payrolls }) => {
                const employeeRequests = payrolls.map((payroll) => {
                    return api.get('/employees', {
                        params: {
                            payroll_id: payroll.id, /* eslint-disable-line camelcase */
                            include_leavers: true, /* eslint-disable-line camelcase */
                        },
                    }).catch((error) => {
                        // since they aren't allowed to see employees if employeeadmin not set
                        if (error.response && error.response.status === 403) {
                            return {
                                data: [],
                            };
                        } else {
                            // catch any other JS related errors
                            throw new Error(error.message);
                        }
                    });
                });

                const employees = await Promise.all(employeeRequests)
                    .then((employeeResponses) => {
                        return employeeResponses.reduce((acc, response) => {
                            acc.push(...response.data);
                            return acc;
                        }, []);
                    });

                appState.setCompaniesAndPayrolls(companies, payrolls);
                appState.setEmployees(employees);
                appState.setSelectedPayroll(payrolls[0]);

                const periodRequests = payrolls.map((payroll) => {
                    return api.get(`/payroll/${payroll.id}/periods`);
                });

                return Promise.all(periodRequests);
            }).then((responses) => {
                _.forEach(responses, (response) => {
                    const payrollId = response.data[0].payroll_id;
                    const periods = response.data.map((period) => {
                        return {
                            id: period.id,
                            year: period.year,
                            period_number: period.period_number, // eslint-disable-line camelcase
                        };
                    });

                    appState.setPeriods(payrollId, periods);
                });

                this.setState({
                    loadingCompleted: true,
                }, () => {
                    appState.setLoaded();
                });
            });
    }

    /**
     * Loads initial required data from the API and starts the transition to the main app
     *
     * @return {void}
     */
    loadAppData () {
        api.get('/user/profile').then((response) => {
            const { forename, surname, mfa_totp } = response.data; // eslint-disable-line camelcase

            userState.setName(forename, surname);
            userState.setMFA(mfa_totp);

            this.setState({
                loginStatus: "success",
                loginMessage: `Welcome ${forename}`,
            }, () => {
                this.loadInitialData();
            });
        });
    }

    /**
     * Processes the login attempt
     *
     * @param {string} username The username to login as
     * @param {string} password The password to use for validation
     *
     * @return {void}
     */
    processLoginAttempt (username, password) {
        api.post("/auth/password", {
            type: "employer",
            username,
            password,
        }).then((response) => {
            const token = response.data.token;

            sessionStorage.setItem("apiToken", token);

            this.setState({
                loginStatus: "success",
            }, () => {
                userState.setLoggedIn(username);
                this.loadAppData();
            });
        }).catch((error) => {
            const message = error.response.data.message || "Username or password incorrect";

            this.setState({
                loginStatus: "failure",
                loginMessage: message,
                validationMessage: message,
            });
        });
    }

    /**
     * Called when a login attempt is made.
     *
     * @param {string} username The username to login as
     * @param {string} password The password to use for validation
     *
     * @return {void}
     */
    handleLoginAttempt = (username, password) => {
        this.setState({
            loginStatus: null,
        }, () => {
            this.processLoginAttempt(username, password);
        });
    }

    /**
     * Redirects to the dashboard once the avatar and container animations are done
     *
     * @return {void}
     */
    handleLoginCompleted = () => {
        const { history } = this.props;

        appState.setEntered();
        history.push(paths.dashboard);
    }

    /**
     * Renders the login page
     *
     * @return {ReactElement} The page component
     */
    render () {
        return (
            <div className={styles.container}>
                <h1 className={titles.title}>Login to ePayslips</h1>
                <div className={styles.loginForm}>
                    <div className={styles.loginWrapper}>
                        <LoginForm
                            backgroundColor="#eee"
                            onLoginAttempt={this.handleLoginAttempt}
                            onLoginCompleted={this.handleLoginCompleted}
                            loggedInUser={{
                                forename: this.props.userState.forename || "",
                                surname: this.props.userState.surname || "",
                            }}
                            loginStatus={this.state.loginStatus}
                            loginMessage={this.state.loginMessage}
                            validationMessage={this.state.validationMessage}
                            loadingCompleted={this.state.loadingCompleted}
                            finalAvatarPosition={{
                                top: this.props.appState.profileMenuPosition.top || 0,
                                left: this.props.appState.profileMenuPosition.left || 0,
                            }}
                            finalAvatarColour={defaultAccentColour}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(appState.attachState(userState.attachState(Login)));
