import React from 'react';
import moment from 'moment';
import axios from 'axios';
import createToasts from '../../../components/toasts/createToasts';
import api from 'lib/api';
import { DataTable } from 'components/';
import { filterObjectEmptyValues } from 'lib/';

class Devices extends React.Component {

    /**
     * Creates an instance of the component
     *
     * @param {object} props Component properties
     */
    constructor (props) {
        super(props);

        this.state = {
            devices: [],
            dataLoaded: false,
        };
    }

    /**
     * Called when the component is added to the DOM
     *
     * @return {void}
     */
    componentDidMount () {
        this.handleApiCall();
    }

    /**
     * Invoked before the component is removed from the DOM
     *
     * @return {void}
     */
    componentWillUnmount () {
        if (this.cancelRequests) {
            this.cancelRequests();
        }
    }

    /**
     * Handles the api call to get the devices
     */
    handleApiCall () {
        api.get("/auth/devices", { component: this })
            .then(({ data }) => {
                this.mapDevicesToState(data);
            }).catch((thrown) => {
                if (!axios.isCancel(thrown)) {
                    createToasts(thrown.response.data, 'error');
                }
            });
    }

    /**
     * Makes an object of the attributes for the data table
     *
     * @return {object} attributes
     */
    getTableAttributes () {
        const docTableHeadings = {
            Name: "Name",
            LastLoggedIn: "Last logged in",
            Location: "Location",
            Trusted: "",
        };

        const colOrder = [
            "Name",
            "LastLoggedIn",
            "Location",
            "Trusted",
        ];

        return {
            docTableHeadings,
            colOrder,
        };
    }

    /**
     * Maps the company array for use in the data table
     *
     * @param {object} devices the companies from the api
     * @return {array} mapped array
     */
    mapDevices (devices) {
        return devices.map((device) => {
            const dateObject = moment(device.last_login.login_time, "YYYY-MM-DDTHH:mm:ss+Z");

            return filterObjectEmptyValues({
                Name: {
                    text: device.friendly_name,
                },
                LastLoggedIn: {
                    text: dateObject.format("Do MMM YYYY"),
                },
                Location: {
                    text: device.last_login.login_log,
                },
            });
        });
    }

    /**
     * Saves the mapped companies to the saved state
     *
     * @param {array} devices An array of companies
     */
    mapDevicesToState (devices) {
        this.setState({
            devices: this.mapDevices(devices),
            dataLoaded: true,
        });
    }

    /**
     * Renders the Device tab
     *
     * @return {ReactElement} The component
     */
    render () {

        const { docTableHeadings, colOrder } = this.getTableAttributes();

        return (
            <DataTable
                key="Devices"
                colOrder={colOrder}
                data={this.state.devices}
                dataLoaded={this.state.dataLoaded}
                headings={docTableHeadings}
            />
        );
    }

}

export default Devices;
